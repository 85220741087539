/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import permissions from "../../../permissions";

export default [
  {
    url: "/",
    name: "Home",
    slug: "home",
    icon: "HomeIcon",
    enabled: permissions.home.route
  },
  {
    url: "/rigs",
    name: "Rigs",
    slug: "rigs",
    icon: "LayersIcon",
    enabled: permissions.rigs.route
  },
  {
    url: "/visits",
    name: "Up Coming Visits",
    slug: "visits",
    icon: "CalendarIcon",
    enabled: permissions.visits.route
  },
  {
    url: "/clients",
    name: "Clients",
    slug: "clients",
    icon: "AwardIcon",
    enabled: permissions.clients.route
  },
  {
    url: "/users",
    name: "Users",
    slug: "users",
    icon: "UserIcon",
    enabled: permissions.users.route
  },
  {
    url: "/logout",
    name: "Logout",
    slug: "logout",
    icon: "LogOutIcon",
    enabled: true
  },
]
