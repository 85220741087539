<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.displayName">



    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="text-right leading-tight hidden sm:block">
        <p class="font-semibold cursor-pointer rounded-full shadow-md" style="padding: 10px;">{{ $store.state.auth.user_name }}</p>
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    confirmLogout(){
      this.$vs.dialog({
        color: "primary",
        type: "confirm",
        title: "Logout",
        text: "Are you sure you want to logout?",
        accept: this.logout
      });
    },
    logout() {
      this.$store.dispatch("auth/logout", this.$router);
    },
  },
}
</script>
